
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { createApolloClient } from "lib/apollo/apollo";
import getEntry from "lib/apollo/queries/entry";
import getGlobals from "lib/apollo/queries/globals";
import Layout from "components/Layout";
import DynamicContent from "components/DynamicContent";
function NotfoundPage({ content = {}, globals = {} }) {
    const localization = {
        locale: content?.locale,
        url: content?.url,
        locale_variant: content?.locale_variant
    };
    return (<Layout globals={globals} content={content} localization={localization}>
      <DynamicContent content={content}/>
    </Layout>);
}
const getStaticProps = async ({ locale }) => {
    const apollo = createApolloClient(null);
    const [content, globals] = await Promise.all([
        getEntry(apollo, "pages", "404", locale),
        getGlobals(apollo, locale),
    ]);
    return {
        props: {
            content,
            globals
        }
    };
};
export default NotfoundPage;

    async function __Next_Translate__getStaticProps__195b3a495de__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195b3a495de__ as getStaticProps }
  